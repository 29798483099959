<template>
  <div class="role-list-div">
    <div class="toolbar light-shadow">
      <span class="title">角色列表</span>
      <el-button class="add-btn" type="primary" icon="el-icon-plus" size="mini" @click="doAdd" v-power="`1000-20-10`"
        >新增</el-button>
    </div>

    <ol
      class="role-list"
      style="overflow: auto"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="!roleData.hasNext"
    >
      <li
        class="role-item basic-shadow"
        v-for="role in roleData.rows"
        :key="role.id"
        v-bind:class="{ 'role-item-active': selectedId == role.id }"
        @click="select(role)"
      >
        <h5>{{ `${role.roleName}(${role.roleCode})` }}</h5>
        <div class="tags">
          <el-tag size="mini" :type="role.flag == 0 ? 'danger' : ''">
            {{ role._caption.flag }}
          </el-tag>
        </div>
      </li>
    </ol>

    <el-dialog :visible.sync="showNewRoleDialog" title="新增角色">
      <new-role-dialog ref="newRoleDialog" @saved="refresh" />
      <template slot="footer">
        <div style="text-align: center">
          <el-button
            :loading="saving"
            type="primary"
            size="mini"
            @click="doSave"
            >保存</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "role-list",
  data() {
    return {
      selectedId: 0,
      showNewRoleDialog: false,
      saving: false,
      roleData: {
        hasNext: true,
        pageNo: 0,
      },
    };
  },
  methods: {
    async doSave() {
      try {
        this.saving = true;
        let formValue = await this.$refs.newRoleDialog.getFormValue();
        await this.$http.doApi("fw-cms-role-save", {}, formValue);
        this.showNewRoleDialog = false;
        this.$refs.newRoleDialog.reset();
        this.refresh();
      } catch (e) {
        console.log(e);
      } finally {
        this.saving = false;
      }
    },
    doAdd() {
      let dialog = this.$refs.newRoleDialog;
      if (dialog) {
        dialog.reset();
      }
      this.showNewRoleDialog = true;
    },
    select(role) {
      this.selectedId = role.id;
      this.$emit("select", this.selectedId);
    },
    refresh() {
      this.roleData.pageNo = 0;
      this.loadMore();
    },
    async loadMore() {
      try {
        this.roleData.pageNo++;
        this.roleData = await this.$http.doApi("fw-cms-role-list", {
          wrapper: true,
          pageNo: this.roleData.pageNo,
          pageSize: 20,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    "new-role-dialog": () => import("./NewRoleDialog"),
  },
};
</script>

<style lang="less">
.role-list-div {
  .toolbar {
    border-bottom: @hair-border-ccc;
    padding: 0.3rem 0.2rem;
    background-color: #c9c9c9;
    margin-bottom: 0.8rem;
    overflow: hidden;
    line-height: 29px;

    .title {
      font-weight: 600;
      padding: 0.3rem 0.4rem;
    }

    .add-btn {
      float: right;
    }
  }

  .role-list {
    list-style: none;
    padding-left: 0;
    padding: 5px 10px;

    .role-item {
      border: 1px #cccccc solid;
      padding: 6px 8px;
      border-radius: 5px;
      display: flex;
      margin-bottom: 0.4rem;
      cursor: pointer;

      h5 {
        margin: 0.3rem 0.4rem;
        display: inline-block;
        flex: 3;
      }

      .tags {
        margin: 0.3rem 0.4rem;
        flex: 2;
        text-align: right;
      }
    }

    .role-item-active {
      border: 1px rgb(255, 83, 83) solid;
      transform: scale(1.01);
      transition: 300ms;
    }
  }
}
</style>